<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contatti</li>
            </ul>
            <h2>Contattaci</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div id="map">
    <iframe src="https://maps.google.com/maps?q=Consorform%20Via%20Renato%20Molinari%202/B,%20Teramo&t=k&z=19&ie=UTF8&iwloc=&output=embed"></iframe>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Chi siamo</span>
                    <h2>Benvenuti in Consorform!</h2>
                    <p>
                        Il Gruppo Consorform, ente di formazione accreditato dalla regione Abruzzo, è una società consortile a responsabilità limitata fondata nel 1988, per volontà di alcune fra le più attive e prestigiose organizzazioni imprenditoriali abruzzesi.
                        Obiettivo primario del Gruppo Consorform è la funzione formativa e consulenziale, che la Società cura dall’analisi dei fabbisogni, alla progettazione, nonché al coordinamento didattico e metodologico, alla gestione amministrativa e finanziaria e, quindi, alla rendicontazione delle attività.
                    </p>
                    <p>
                        In tal senso sono stati programmati e gestiti numerosi interventi a carattere regionale, interregionale, transnazionale e finanziati, oppure commissionati direttamente dai clienti.
                        Il Gruppo Consorform, pur componendosi di uno staff giovane e dinamico, ha acquisito nel tempo una vasta esperienza che permette di fornire servizi formativi ai privati e alle aziende basati sul concetto chiave che formare significa far acquisire competenze e professionalità, tali da creare figure professionalmente competitive nel mondo del lavoro.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">dalla formazione alla professione</span>
                    <h2>Formazione Professionale</h2>
                    <p>
                        Oltre alle attività di garanzia del credito, altrettanto peculiare è, nell’ambito della gamma di servizi offerti, la funzione formativa e consulenziale, che il Consorform cura sin dalla valutazione dei fabbisogni di qualificazione, riqualificazione e aggiornamento professionale emergenti dall’intero territorio regionale, alla progettazione dei singoli interventi relativi ai vari programmi di finanziamento promossi dagli Enti preposti (Regione Abuzzo, Ministero del Lavoro e delle Politiche Sociali, Unione Europea etc.), nonché al coordinamento didattico e metodologico, alla gestione amministrativa e finanziaria e, quindi, alla rendicontazione delle attività.
                    </p>
                    <p>
                        In tal senso sono state progettate e gestite diverse tipologie di intervento, sia a carattere regionale che interregionale, in regime di convenzione/autorizzazione con la Regione Abruzzo, il Fondo Sociale Europeo, il Ministero del Lavoro e l’Unione Europea.
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Informazioni</span>
                    <h2>Mettiti in contatto</h2>
                    <p>Ci piace ascoltare i lettori. Apprezziamo davvero che tu abbia dedicato del tempo per metterci in contatto.</p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Dove siamo</h3>
                            <p>Via Molinari 2/B - TERAMO</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contatti</h3>
                            <p>Mobile: <a href="tel:+3908614419200">(+39) 0861-4419200</a></p>
                            <p>e-mail: <a href="mailto:formazione@consorform.it">formazione@consorform.it</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Siamo disponibili</h3>
                            <p>Lunedì-Venerdì: 9:00–13:00/14:00–19:00<br>Sabato: 9:00-13:00</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Come possiamo aiutarti?</h2>
                    <p>L'indirizzo email non verrà pubblicato. i campi richiesti sono contrassegnati dal simbolo*</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nome e Cognome">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Nome e Cognome richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 50 caratteri.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="la tua e-mail">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="cellulare">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">un contatto telefonico è obbligatorio</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="scrivi il tuo messaggio..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">il messaggio è obbligatorio</div>
                                </div>
                            </div>
                            <h2 class="mt-3">Quale corso sei interessato?</h2>
                            <p>seleziona il corso interessato alla quale vuoi avere informazioni*</p>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select name="sceltaCorso" ngModel id="sceltaCorso" #sceltaCorso="ngModel" style="display: block;
                                    width: 100%;
                                    border: none;
                                    color: var(--blackColor);
                                    background-color: #F3F6F9;
                                    height: 50px;
                                    padding-left: 15px;
                                    border-radius: 5px;
                                    font-size: var(--fontSize);
                                    font-weight: 600">
                                        <option value="{{elencoCorsiRow['corso']['titolo']}}" *ngFor="let elencoCorsiRow of elencoCorsi">{{elencoCorsiRow['corso']['titolo']}}</option>
                                      </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Invia Richiesta<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
